//Core
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import get from 'lodash.get';
import { isMobile as globalIsMobile } from 'react-device-detect';
import classNames from 'classnames';

//Local
import { Layout, TopBanner2, TopBannerText } from '../../components';
import { DepartmentsSec } from '../../components/mediaCenterSections/blogCategory';
import { PreviewSec, ItemsSec } from '../../components/Column/News';
import * as MediaCenterActions from '../../redux/mediaCenter/actions';
import banner from '../../images/hero-hr-blog.png';
import mobileBanner from '../../images/hero-hr-blog-mobile.png';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import CompanyPromoTop from '../../components/About/PromoTop';
import columnStyles from '../../styles/pages/column.module.scss';
import hrStyles from '../../styles/pages/hrBlog.module.scss';
import { SITE_METADATA } from '../../constants';

const MediaCenterHr = ({ location }) => {
  const tag = decodeURIComponent(get(location, 'hash', '').replace('#', ''));
  const hrPage = useSelector((state) => state.mediaCenter.hrPage);
  const hrArticles = useSelector((state) => state.mediaCenter.hrArticles);
  const dispatch = useDispatch();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(globalIsMobile);
  });

  // Did mount
  useEffect(() => {
    dispatch(MediaCenterActions.getHrPageAsync());
    dispatch(MediaCenterActions.getHrArticlesAsync());
  }, [dispatch]);

  return (
    <Layout
      isHrPage
      SEOProps={{
        title: SITE_METADATA.hrBlog.title,
        description: SITE_METADATA.hrBlog.description,
      }}
    >
      <TopBanner2 bgImg={isMobile ? mobileBanner : banner} textAlign="center">
        <TopBannerText>HRからのお知らせ</TopBannerText>
      </TopBanner2>
      <Breadcrumb
        customLevels={[
          {
            path: '/',
            title: 'トップ',
          },
          {
            path: '/recruit',
            title: 'Career',
          },
          {
            path: '/hr-blog',
            title: 'HRからのお知らせ',
          },
        ]}
      />

      <PreviewSec
        title={get(hrPage, 'pageTitle')}
        subtitle={get(hrPage, 'pageSubtitle')}
        card={{
          ...get(hrPage, 'primaryArticle.fields', {}),
          imgUrl: get(
            hrPage,
            'primaryArticle.fields.previewImage.fields.file.url'
          ),
          url: `/media_center/${get(
            hrPage,
            'primaryArticle.fields.blogSection.fields.urlKey'
          )}/article/${get(hrPage, 'primaryArticle.sys.id')}`,
          tags: get(hrPage, 'primaryArticle.fields.tags', []).map(
            (item) => item.fields.title
          ),
        }}
      />

      <div className={columnStyles.container}>
        <ItemsSec
          title={get(hrPage, 'secondSectionTitle')}
          tags={get(hrPage, 'tags', [])}
          items={hrArticles}
          selectedTag={tag}
          hasLoadMore={isMobile}
        />
      </div>
      <div className={classNames(hrStyles.hrWrap)}>
        <div className={columnStyles.container}>
          <DepartmentsSec
            title={get(hrPage, 'thirdSectionTitle')}
            items={get(hrPage, 'thirdSectionDepartments', [])}
            isHrPage
          />
        </div>
      </div>

      <CompanyPromoTop redirectLink="https://boards.greenhouse.io/paidyinc" />
    </Layout>
  );
};

export default MediaCenterHr;
